<template>
    <div>
        <b-row>
        <b-col><b>Timebank Roles</b></b-col>
        <b-col><b-button :disabled="isNew || !isEditable" variant="primary" @click="onAddTimebankRole(timebank_roles)">Add timebank role</b-button></b-col>
        </b-row>
        <br />
        <b-form inline v-for="(timebankRole, index) in timebank_roles" :key="index">
            <div>
                <b-form-input placeholder="Name" :disabled="!isEditable" v-model="timebankRole.name" class="m-2"/><br />
            </div>
            <div>
                <b-form-input placeholder="Rate" :disabled="!isEditable" v-model="timebankRole.rate" class="m-2" /><br />
            </div>
            <div>
                <b-form-input placeholder="Hours" :disabled="!isEditable" v-model="timebankRole.hours" class="m-2" /><br />
            </div>
            <b-button variant="primary" :disabled="!isEditable" @click="onDeleteTimebankRole(timebankRole, timebank_roles.indexOf(timebankRole))">Delete</b-button>
            <b-button :disabled="!isEditable" @click="onSaveTimebankRole(timebankRole)" variant="primary" class="m-1">Save</b-button>
        </b-form>
        <span v-if="Object.keys( errors ).length != 0" class="error">
            {{ errors }}
        </span>
    </div>
</template>


<script>

import confirmationModal from '@/api/confirmation'
import crudMixin from '@/api/crud'
import { deleteConsultingTimebankRole, updateConsultingTimebankRole, addConsultingTimebankRole, getConsultingTimebankRoleLoggedTime } from '@/api/consulting.api'
import Vue from 'vue'


export default {
    mixins: [crudMixin, confirmationModal],
    props : {
        timebank_roles : Array,
        timebank: Object,
        projectid: [String, Number]
    },
    data(){
        return{
            loggedTime: 0
        }
    },
    computed:{
        computedTotalHours: function() {
            return this.timebank_roles.reduce((total, timebankRole) => total +  parseFloat(timebankRole.hours || 0), 0);
        },
        isNew: function() {
            return this.timebank.id != parseInt(this.timebank.id)
        },
        isEditable: function() {
            return !this.timebank.activated;
        },
    },
    methods:{
        onAddTimebankRole: function(timebank_roles) {
            timebank_roles.push({'timebank': {'id': this.timebank.id}, 'project_id': this.projectid});
        },
        onDeleteTimebankRole: function(timebankRole, index) {
            if (timebankRole.id) {
                this.timebankRoleId = timebankRole.id;
                // confirmModal(message, okTitle, cancelTitle)
                this.confirmModal().then((result) => {
                    if (result){
                        this.deleteObject(
                            deleteConsultingTimebankRole(this.timebankRoleId),
                            () => {
                                this.timebank_roles.splice(index, 1);
                            }
                        )
                    }
                })
            }
        },
        onSaveTimebankRole: function(timebankRole){
            if (timebankRole.id) {
                if (!timebankRole.timebank) {
                    timebankRole.timebank = {'id': this.timebank.id};
                }
                var apiCall = updateConsultingTimebankRole(timebankRole.id, timebankRole);
            } else {
                apiCall = addConsultingTimebankRole(timebankRole);
            }
            this.saveObject(apiCall, (res) => {
                var location = res.data.location;
                Vue.set( timebankRole, 'id', parseInt(location.substring(location.lastIndexOf('/') + 1)));
            })
        },
        getConsultingTimebankRoleLoggedTime: function (id) {
            this.call(
                getConsultingTimebankRoleLoggedTime(id),
                (res) => {
                    this.loggedTime = res.data.total_time
                }
            )
        },
        progressPercentage(timebankRoleHours, id) {
            // // Fetch consultant timebank roles logged time to reflect real-time progress
            this.getConsultingTimebankRoleLoggedTime(id)
            return Math.min((this.loggedTime / timebankRoleHours) * 100, 100);
        }
    },
    watch: {
        computedTotalHours: {
            handler(totalHours){
                this.$emit('updateTotalHours',totalHours);
            },
            immediate: true,
        },
    }
}
</script>