<template>
<div>
    <inline-form-row label="Name" type="text" :disabled="!isEditable" v-model="timebank.name" :errors="errors['name']" />
    <inline-form-row label="Total hours" :disabled="true" type="text" v-model="timebankHours"  :errors="errors['hours']" />
    <inline-form-row label="Expiration Date" _type="b-form-datepicker" v-model="timebank.expiration_date" :errors="errors['expiration_date']" helpText="Date the timebank ends" />
    <BMSTimebankRole :timebank_roles="timebank.timebank_roles" :projectid="projectid" :timebank="timebank" @updateTotalHours="handleTotalHours"/>
    <div class="text-right">
      <b-button class="m-1" v-if="isEditable & !isNew" @click.prevent="createInvoice()">Create Invoice</b-button>
      <b-button class="m-1" @click="onSaveTimebank(timebank)">Save Timebank</b-button>
      <b-button class="m-1" v-if="isEditable"  @click="onDeleteTimebank(timebank)">Delete Timebank</b-button>
    </div>
</div>
</template>
<script>

import { createTimebank,updateTimebank, deleteTimebank, createInvoice } from '@/api/timebanks.api'
import InlineFormRow from '@/components/InlineFormRow'
import BMSTimebankRole from '@/components/BMSTimebankRole'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'
import Vue from 'vue'

export default {
    mixins: [crudMixin, confirmationModal],
    props : {
        timebank : Object,
        projectid : [String, Number],
    },
    data() {
        return {
            timebankHours: null,
            timebankRoleErrors: []
        }
    },
    computed : {
        isNew: function() {
            return this.timebank.id != parseInt(this.timebank.id)
        },
        isEditable: function() {
            return !this.timebank.activated;
        },
    },
    methods : {
        onSaveTimebank: function(timebank) {
            // Create a payload so we can manipulate the fields
            var payload = JSON.parse(JSON.stringify(timebank));
            payload.project = { id: this.projectid };
            delete payload.timebank_roles;
            if (timebank.id) {
                var apiCall = updateTimebank(timebank.id, payload);
            } else {
                apiCall = createTimebank(payload);
            }
            this.saveObject(
                apiCall,
                (res) => {
                    var location = res.data.location;
                    Vue.set( timebank, 'id', parseInt(location.substring(location.lastIndexOf('/') + 1)));
                }
            )
        },
        onDeleteTimebank: function(timebank) {
            if (timebank.id) {
                this.timebankId = timebank.id;
                // confirmModal(message, okTitle, cancelTitle)
                this.confirmModal().then((result) => {
                    if (result){
                        this.deleteObject(
                            deleteTimebank(this.timebankId),
                            () => {
                                this.$emit('deleteTimebank');
                            }
                        )
                    }
                })
            }
        },
        handleTotalHours: function(totalHours) {
            this.timebankHours = totalHours;
        },
        createInvoice(showBanner=false) {
           var apiCall = createInvoice(this.timebank.id)
           var callback = (res) => {
                let findId = res.data.location.split('/');
                let invoiceId = findId[findId.length-1];
                this.$router.push(`/invoices/${invoiceId}`);
                this.$awn.success('Invoice created successfully!');
           }
           this.saveObject(
                apiCall,
                callback,
                showBanner
            )
        }
    },
    components: {
        InlineFormRow,
        BMSTimebankRole
    }
}
</script>