import httpClient from './httpClient';

const END_POINT = '/timebanks';

const getAllTimebanks = () => httpClient.get(END_POINT);

const createTimebank = (timebank) => httpClient.post(END_POINT, { timebank });

const updateTimebank = (id, timebank) => httpClient.put(END_POINT + '/' + id, { timebank } );

const deleteTimebank = (id) => httpClient.delete(END_POINT + '/' + id);

const createInvoice = (id) => httpClient.post(END_POINT + '/' + id + '/create-invoice/');

export {
    getAllTimebanks,
    createTimebank,
    updateTimebank,
    deleteTimebank,
    createInvoice
}